/* eslint-disable @typescript-eslint/no-explicit-any */
type ConsentStatus = 'denied' | 'granted';

interface Consent {
  analytics_storage: ConsentStatus;
}

interface Analytics {
  emitEvent: (name: string, args: Record<string, any>) => void;
  setConsent: (consent: Consent) => void;
  initialize: (id: string) => void;
}

const analyticsUtils = (): Analytics => {
  const { gtag, initialize } = useGtag();

  const setConsent = (consent: Consent): void => {
    gtag('consent', 'update', consent);
  };

  const emitEvent = (name: string, args: Record<string, any>): void => {
    if (import.meta.server) {
      return;
    }

    gtag('event', 'customer:' + name, args);
  };

  return { emitEvent, setConsent, initialize };
};

export { analyticsUtils };
export type { ConsentStatus };
