import { default as indexbO7fuQirP2Meta } from "/app/pages/account/addresses/index.vue?macro=true";
import { default as indexmJLWlbYWJtMeta } from "/app/pages/account/cards/index.vue?macro=true";
import { default as indexQWKFaMHH2OMeta } from "/app/pages/account/index.vue?macro=true";
import { default as indexv0iIYE4xFlMeta } from "/app/pages/account/orders/index.vue?macro=true";
import { default as indexKCiVKYb9p3Meta } from "/app/pages/account/profile/index.vue?macro=true";
import { default as indexb7wgDeA968Meta } from "/app/pages/booking/index.vue?macro=true";
import { default as _91uuid_93N6ASDTV0H1Meta } from "/app/pages/checkout/payment/[uuid].vue?macro=true";
import { default as indexX1eVRlS9f6Meta } from "/app/pages/checkout/payment/index.vue?macro=true";
import { default as indexJiDRxP5FCqMeta } from "/app/pages/checkout/takeaway/index.vue?macro=true";
import { default as _91uuid_93gbDLH57EYEMeta } from "/app/pages/checkout/trackorder/[uuid].vue?macro=true";
import { default as indexnn59om8GTAMeta } from "/app/pages/cookiespolicy/index.vue?macro=true";
import { default as indexjpdP4BPFKIMeta } from "/app/pages/help/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexqH4y7yddqEMeta } from "/app/pages/login/confirmemail/index.vue?macro=true";
import { default as indexx9JqtpSSLUMeta } from "/app/pages/login/forgotpassword/index.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as indexGgqkAKCfamMeta } from "/app/pages/login/register/index.vue?macro=true";
import { default as _91uuid_93ADpIEf4LItMeta } from "/app/pages/login/resetpassword/[uuid].vue?macro=true";
import { default as indexybYBpxNvctMeta } from "/app/pages/logout/index.vue?macro=true";
import { default as indexwJEmXChT2GMeta } from "/app/pages/maintenance/index.vue?macro=true";
import { default as indexKEaHWmyZdKMeta } from "/app/pages/menu/index.vue?macro=true";
import { default as indexY2k16F7dm4Meta } from "/app/pages/privacypolicy/index.vue?macro=true";
import { default as indexZOdoscj6bpMeta } from "/app/pages/termsandconditions/index.vue?macro=true";
export default [
  {
    name: "account-addresses",
    path: "/account/addresses",
    meta: indexbO7fuQirP2Meta || {},
    component: () => import("/app/pages/account/addresses/index.vue")
  },
  {
    name: "account-cards",
    path: "/account/cards",
    meta: indexmJLWlbYWJtMeta || {},
    component: () => import("/app/pages/account/cards/index.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "account-orders",
    path: "/account/orders",
    meta: indexv0iIYE4xFlMeta || {},
    component: () => import("/app/pages/account/orders/index.vue")
  },
  {
    name: "account-profile",
    path: "/account/profile",
    meta: indexKCiVKYb9p3Meta || {},
    component: () => import("/app/pages/account/profile/index.vue")
  },
  {
    name: "booking",
    path: "/booking",
    component: () => import("/app/pages/booking/index.vue")
  },
  {
    name: "checkout-payment-uuid",
    path: "/checkout/payment/:uuid()",
    meta: _91uuid_93N6ASDTV0H1Meta || {},
    component: () => import("/app/pages/checkout/payment/[uuid].vue")
  },
  {
    name: "checkout-payment",
    path: "/checkout/payment",
    meta: indexX1eVRlS9f6Meta || {},
    component: () => import("/app/pages/checkout/payment/index.vue")
  },
  {
    name: "checkout-takeaway",
    path: "/checkout/takeaway",
    meta: indexJiDRxP5FCqMeta || {},
    component: () => import("/app/pages/checkout/takeaway/index.vue")
  },
  {
    name: "checkout-trackorder-uuid",
    path: "/checkout/trackorder/:uuid()",
    meta: _91uuid_93gbDLH57EYEMeta || {},
    component: () => import("/app/pages/checkout/trackorder/[uuid].vue")
  },
  {
    name: "cookiespolicy",
    path: "/cookiespolicy",
    component: () => import("/app/pages/cookiespolicy/index.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/app/pages/help/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login-confirmemail",
    path: "/login/confirmemail",
    meta: indexqH4y7yddqEMeta || {},
    component: () => import("/app/pages/login/confirmemail/index.vue")
  },
  {
    name: "login-forgotpassword",
    path: "/login/forgotpassword",
    meta: indexx9JqtpSSLUMeta || {},
    component: () => import("/app/pages/login/forgotpassword/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: "login-register",
    path: "/login/register",
    meta: indexGgqkAKCfamMeta || {},
    component: () => import("/app/pages/login/register/index.vue")
  },
  {
    name: "login-resetpassword-uuid",
    path: "/login/resetpassword/:uuid()",
    component: () => import("/app/pages/login/resetpassword/[uuid].vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: indexybYBpxNvctMeta || {},
    component: () => import("/app/pages/logout/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: indexwJEmXChT2GMeta || {},
    component: () => import("/app/pages/maintenance/index.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: indexKEaHWmyZdKMeta || {},
    component: () => import("/app/pages/menu/index.vue")
  },
  {
    name: "privacypolicy",
    path: "/privacypolicy",
    component: () => import("/app/pages/privacypolicy/index.vue")
  },
  {
    name: "termsandconditions",
    path: "/termsandconditions",
    component: () => import("/app/pages/termsandconditions/index.vue")
  }
]