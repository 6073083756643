export default defineNuxtRouteMiddleware((to) => {
  const store = usePreferenceStore();

  const isUnderMaintenance = store.preference.general.maintenance;
  const isMaintenanceRoute = to.name === 'maintenance';

  if (isUnderMaintenance && !isMaintenanceRoute) {
    return navigateTo({ name: 'maintenance' });
  }

  if (!isUnderMaintenance && isMaintenanceRoute) {
    return navigateTo({ name: 'index' });
  }
});
