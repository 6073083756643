import validate from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_a3iol6lk26qn2tqtity6eb5ara/node_modules/nuxt/dist/pages/runtime/validate.js";
import invalid_45routes_45global from "/app/middleware/invalid-routes.global.ts";
import maintenance_45global from "/app/middleware/maintenance.global.ts";
import track_45navigation_45global from "/app/middleware/track-navigation.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_a3iol6lk26qn2tqtity6eb5ara/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  invalid_45routes_45global,
  maintenance_45global,
  track_45navigation_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "account-desktop": () => import("/app/middleware/account-desktop.ts"),
  "account-mobile": () => import("/app/middleware/account-mobile.ts"),
  "auth-and-menu": () => import("/app/middleware/auth-and-menu.ts"),
  "auth-or-guest": () => import("/app/middleware/auth-or-guest.ts"),
  "auth-or-home": () => import("/app/middleware/auth-or-home.ts"),
  checkout: () => import("/app/middleware/checkout.ts"),
  "confirm-email": () => import("/app/middleware/confirm-email.ts")
}